import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 720.000000 700.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,700.000000) scale(0.100000,-0.100000)">
       <path d="M3510 5384 c-197 -202 -423 -319 -736 -380 -181 -36 -447 -40 -584
-10 -133 29 -239 71 -322 129 -9 6 -19 9 -22 6 -3 -3 -11 -79 -17 -170 -10
-140 -10 -201 5 -410 9 -136 21 -273 26 -305 12 -86 60 -357 65 -371 4 -12 76
-19 87 -8 5 5 -3 72 -21 190 -23 142 -41 304 -51 438 -11 153 -14 456 -5 465
3 4 29 -2 58 -12 331 -118 754 -97 1125 55 42 18 82 35 87 39 6 4 31 19 56 33
77 42 94 52 99 57 3 3 21 17 40 30 19 13 62 48 96 77 34 29 67 53 74 53 7 0
37 -22 67 -50 71 -65 196 -148 308 -203 351 -174 835 -216 1171 -101 39 13 76
24 82 24 9 0 12 -48 12 -177 0 -244 -29 -556 -75 -813 -20 -116 -22 -110 29
-110 50 0 49 -1 66 90 57 295 80 535 80 840 0 219 -8 340 -21 340 -5 0 -32
-15 -61 -34 -62 -39 -160 -76 -273 -102 -122 -28 -398 -26 -565 4 -154 29
-304 78 -433 144 -117 60 -193 116 -303 220 -45 43 -83 78 -85 78 -3 0 -29
-25 -59 -56z"/>
<path d="M3515 4915 c-14 -13 -25 -35 -25 -47 0 -29 26 -68 45 -68 21 0 22
-17 7 -89 -44 -217 -173 -419 -296 -466 -136 -52 -329 55 -528 292 -61 72 -73
93 -78 131 -6 51 -29 74 -57 56 -29 -18 -23 -65 11 -84 20 -11 35 -31 47 -65
24 -69 105 -299 174 -490 31 -88 64 -175 72 -192 l15 -33 658 0 c514 0 660 3
667 13 11 12 83 202 148 387 20 58 52 148 70 200 19 52 40 114 47 138 7 23 18
42 24 42 7 0 22 8 34 18 18 14 21 25 16 45 -9 36 -43 45 -69 18 -18 -17 -19
-24 -9 -47 11 -24 9 -30 -22 -68 -200 -242 -362 -364 -496 -374 -50 -4 -66 0
-111 24 -106 58 -220 243 -268 438 l-23 88 31 32 c37 39 39 69 6 101 -31 32
-59 32 -90 0z m85 -725 c16 -33 30 -67 29 -77 -1 -25 -52 -125 -64 -127 -5 0
-25 27 -43 61 l-33 63 21 47 c22 51 47 93 55 93 3 0 18 -27 35 -60z m-332 -66
c15 -10 22 -25 22 -48 0 -41 -15 -56 -57 -56 -64 0 -85 87 -26 108 38 14 36
14 61 -4z m671 -9 c17 -16 18 -60 1 -80 -7 -8 -27 -15 -45 -15 -38 0 -57 19
-57 57 0 53 61 76 101 38z"/>
<path d="M3566 3415 c-58 -20 -84 -74 -60 -121 19 -36 39 -50 99 -70 59 -19
85 -45 77 -76 -7 -31 -20 -38 -67 -38 -46 0 -57 6 -74 38 -7 14 -21 22 -36 22
-29 0 -30 -2 -10 -41 55 -105 245 -84 245 27 0 48 -35 82 -115 113 -65 24 -70
29 -73 58 -4 39 21 56 76 51 31 -2 39 -8 51 -35 11 -24 21 -33 38 -33 28 0 30
20 6 60 -28 49 -93 67 -157 45z"/>
<path d="M4815 3423 c-75 -16 -115 -78 -115 -179 0 -115 56 -184 149 -184 50
0 83 12 110 41 17 18 21 36 21 86 l0 64 -67 -3 c-62 -3 -68 -5 -71 -25 -3 -21
1 -23 37 -23 41 0 41 0 41 -39 0 -44 -10 -51 -74 -51 -73 0 -102 58 -86 173
10 69 33 97 80 97 42 0 59 -9 76 -41 18 -35 54 -40 54 -8 0 27 -62 89 -88 89
-11 0 -26 2 -34 4 -7 2 -22 2 -33 -1z"/>
<path d="M2298 3414 c-5 -4 -8 -86 -8 -182 l0 -175 80 6 c89 6 114 17 156 73
24 31 28 45 28 103 0 75 -19 119 -67 155 -22 16 -46 22 -105 24 -42 2 -80 0
-84 -4z m162 -56 c38 -24 52 -129 25 -193 -15 -38 -46 -55 -101 -55 l-34 0 0
136 0 136 46 -7 c26 -4 55 -11 64 -17z"/>
<path d="M2699 3393 c-75 -195 -119 -315 -119 -323 0 -14 36 -12 47 3 6 6 16
29 23 50 8 27 16 37 27 33 8 -3 41 -6 73 -6 l58 0 15 -42 c11 -35 18 -43 38
-43 16 0 23 5 21 15 -5 26 -115 315 -125 328 -17 21 -47 14 -58 -15z m60 -113
c30 -79 29 -82 -26 -78 -42 3 -48 6 -45 23 4 23 43 117 47 113 2 -2 13 -28 24
-58z"/>
<path d="M2870 3400 c0 -18 7 -20 55 -20 l55 0 0 -147 c0 -82 4 -154 9 -161 5
-8 17 -12 27 -10 18 3 19 16 22 161 l3 157 54 0 c48 0 55 2 55 20 0 19 -7 20
-140 20 -133 0 -140 -1 -140 -20z"/>
<path d="M3269 3393 c-6 -16 -19 -50 -29 -78 -10 -27 -34 -90 -54 -140 -20
-49 -36 -96 -36 -102 0 -7 11 -13 24 -13 19 0 27 8 37 38 16 49 24 59 51 56
13 -1 43 -3 68 -3 45 -1 46 -2 61 -43 13 -34 21 -44 42 -46 15 -2 27 -1 27 3
0 3 -16 47 -36 98 -20 50 -47 124 -61 162 -14 39 -30 76 -35 83 -18 21 -48 14
-59 -15z m60 -113 c30 -78 29 -80 -29 -80 -27 0 -50 1 -50 3 0 1 9 27 20 57
12 30 23 61 26 69 6 19 9 15 33 -49z"/>
<path d="M3920 3239 l0 -180 23 3 c20 3 22 9 25 64 2 47 8 66 23 80 24 21 13
31 101 -84 36 -47 54 -62 73 -62 14 0 25 2 25 5 0 3 -31 47 -70 99 -38 52 -70
98 -70 101 0 4 30 40 66 81 l66 74 -30 0 c-25 0 -42 -13 -97 -75 -37 -41 -72
-75 -76 -75 -5 0 -9 34 -9 75 0 73 -1 75 -25 75 l-25 0 0 -181z"/>
<path d="M4232 3243 c3 -175 3 -178 25 -181 22 -3 22 -2 25 177 l3 181 -28 0
-27 0 2 -177z"/>
<path d="M4362 3243 c3 -170 4 -178 23 -178 19 0 20 8 23 123 1 67 6 122 10
122 4 0 27 -33 51 -73 65 -105 120 -177 135 -177 29 0 35 28 35 193 l1 167
-30 0 -29 0 -3 -127 -3 -128 -83 128 c-69 107 -86 127 -107 127 l-25 0 2 -177z"/>
<path d="M2643 2362 c-17 -11 5 -42 113 -159 194 -211 478 -444 741 -609 l73
-46 37 23 c161 98 415 288 558 416 117 105 296 293 328 343 l19 30 -52 0 -53
0 -151 -149 c-163 -161 -211 -203 -358 -318 -123 -96 -310 -223 -328 -223 -25
0 -308 200 -424 300 -95 81 -214 190 -303 279 -114 113 -159 139 -200 113z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
